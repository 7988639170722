import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import AgentHomePage from "./LoPages/lopage";
import { MortgageGuideRoutes } from 'GenericMortgageGuides';
import Footer from './components/FooterNew/index';

const location = window.location.pathname;
const baseName = process.env.REACT_APP_BASENAME;

let basePath = baseName;
if (baseName === "/homestyle-renovation") {
  basePath = basePath + "/";
}

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/guides" element=
        {location !== baseName && location !== "/homestyle-renovation/" ? (
          <MortgageGuideRoutes
            homePath={basePath}
            showHeader={true}
            showFooter={true}
            FooterComponent={Footer}
          />) : ("")}/>
        <Route path="/:loName" element={<AgentHomePage />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;