import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./banner.css";
import htmlparser from "html-react-parser";
import desktop from "../../LoJSONs/desktop.mp4";
import mobile from "../../LoJSONs/mobile.mp4";
import Kurthuels from "../../LoJSONs/kurt-huels-banner.webp";
import {
    capitalizeFirstLetter,
    formatPhoneNumber,
    addInternationalCode,
} from "../Utils";
const imgCDNURL = process.env.REACT_APP_CDN_URL;
const desktopVideoId = "banner-video-desktop";
const mobileVideoId = "banner-video-mobile";

export default function Banner({ loanOfficerData, loanOfficerActive }) {

    const loImage = loanOfficerData ? loanOfficerData.profileImage : "";
    const loanOfficerName = loanOfficerData ? capitalizeFirstLetter(loanOfficerData.name) : "";
    const loanOfficerPhoneNumber = loanOfficerData ? (loanOfficerData.workPhoneNumber ? loanOfficerData.workPhoneNumber : loanOfficerData.workPhoneNumber) : "";
    const loanOfficerPhoneNumberFormatted = loanOfficerData ? formatPhoneNumber(loanOfficerPhoneNumber) : "";
    const loanOfficerPhoneNumberWithInternationalCode = loanOfficerData ? addInternationalCode(loanOfficerPhoneNumber, "+1") : "";
    const loanOfficerPhoneNumberInTitle = loanOfficerData ? "Contact me at " + loanOfficerPhoneNumberFormatted : "";

    // Construct dynamic map link based on address
    const mapLink = loanOfficerData ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(loanOfficerData.streetAddress + ', ' + loanOfficerData.city + ', ' + loanOfficerData.state + ' ' + loanOfficerData.zipCode)}` : "#";


    return (
        <>
            <div className="banner">
                {window.innerWidth > 991.9 ? (
                    <video autoPlay muted loop playsInline id={desktopVideoId} className="banner-deskvideo">
                        <source src={desktop} type="video/mp4" />
                    </video>
                ) : (
                    <video autoPlay muted loop playsInline id={mobileVideoId} className="banner-mobvideo">
                        <source src={mobile} type="video/mp4" />
                    </video>
                )}
                <Container className="bannercontainer">
                    <Row>
                        <Col sm={12} md={12} xl={12} lg={12}>
                            <h1 className="tagline">What is a <span>HomeStyle® Renovation</span> <br/>Mortgage?</h1>
                            <h1 className="taglinemob">What is a <br/><span>HomeStyle® Renovation</span> <br/>Mortgage?</h1>
                            <p className="description">Are you dreaming of transforming a fixer upper into your dream home? Or perhaps you're looking to refinance your existing property while also making those long-awaited renovations? <br/><span>Look no further than the HomeStyle® Renovation Mortgage.</span></p>
                            <p className="description2">The HomeStyle® Renovation Mortgage is <span>your gateway to purchasing a property or refinancing your current loan</span>, <br/>with the added benefit of including funds to cover repair costs, remodeling, renovations, or even energy improvements to the property.</p>
                        </Col>
                    </Row>
                    <Row className="lo-banner-row">
                        <Col sm={12} md={12} xl={6} lg={6}>
                            <img
                                src={loImage}
                                className="kurt-huels-banner"
                                alt={htmlparser(loanOfficerName)}
                                title={htmlparser(loanOfficerName)}
                                width={"200px"}
                            />
                        </Col>
                        <Col sm={12} md={12} xl={6} lg={6}>
                            <div className="lo-section">
                                {loanOfficerData ? (
                                    <>
                                        <h2 className="lo-banner-name">{htmlparser(loanOfficerName)}</h2>
                                        <p className="lo-banner-details">
                                            NMLS {htmlparser(loanOfficerData.nmls)}
                                            <br />
                                            {htmlparser(loanOfficerData.designation)}
                                            <br />
                                            D:{" "}
                                            {loanOfficerPhoneNumber ? (
                                                <>
                                                    <a
                                                        href={"tel:" + loanOfficerPhoneNumberWithInternationalCode}
                                                        title={loanOfficerPhoneNumberInTitle}
                                                        aria-label={loanOfficerPhoneNumberInTitle}
                                                    >
                                                        {loanOfficerPhoneNumberFormatted}
                                                    </a>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            <br />
                                            <a
                                                href={"mailto:" + loanOfficerData.emailAddress}
                                                title={"Email me at " + loanOfficerData.emailAddress}
                                                aria-label={"Email me at " + loanOfficerData.emailAddress}
                                            >
                                                {loanOfficerData.emailAddress}
                                            </a>
                                            <br />
                                            <a
                                                href={"https://" + loanOfficerData.userWebsite}
                                                title={loanOfficerData.userWebsite}
                                                aria-label={loanOfficerData.userWebsite}
                                                target="_blank"
                                            >
                                                {loanOfficerData.userWebsite.replace("https://", "")}
                                            </a>
                                            <br />
                                            <a
                                                href={mapLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {htmlparser(loanOfficerData.streetAddress)}<br />
                                                {htmlparser(loanOfficerData.city)}, {htmlparser(loanOfficerData.state)} {htmlparser(loanOfficerData.zipCode)}
                                            </a>
                                        </p>
                                    </>
                                ) : (
                                    "Error: No loan officer data found."
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}